import React from "react"
import Layout from "@components/layout"
import Meta from "@components/meta"
import * as Heading from "@components/heading"
import BreadCrumbs from "../components/breadcrumbs";

const Privacy = () => (

  <Layout>
    <Meta
      title="プライバシーポリシー"
      desc="木原興業のプライバシーポリシー"
    />{ }
    <Heading.H1 text="プライバシーポリシー" />
    <div className="px-5 mb-12 lg:max-w-4xl lg:mx-auto">
      <BreadCrumbs
        lists={[
          {
            string: "会社情報",
            path: "/company",
          },
          {
            string: "プライバシーポリシー",
          }
        ]}
      />
      <p>木原興業株式会社（以下、当社という）は、特定の個人を識別できる情報(以下、個人情報という)を適切に取扱うことを当社の重要な社会的責務と考えています。ご提供いただいた個人情報については、個人情報保護に関する法律、その他関係法令を遵守すると共に、以下の通り方針を定め役員および従業員全員に周知徹底を図っていきます。</p>
      <Heading.H3 text="利用目的の明確化" />
      <p>当社は、個人情報の収集に際し、正当な理由がある場合を除き、利用目的を明らかにします。また、不正な方法での収集は行いません。</p>
      <Heading.H3 text="利用目的の順守" />
      <p>当社は、正当な理由がある場合を除き、個人情報収集の際に示した利用目的の範囲内でのみこれを利用しそれ以外の目的には利用しません。</p>
      <Heading.H3 text="セキュリティ対策の実施" />
      <p>当社は、適切なセキュリティ対策を実施し、個人情報の漏洩、紛失、破壊、または不正アクセスなどの事故を防止するよう努めます。また、万一事故が発生した場合には、速やかに是正措置を実施します。</p>
      <Heading.H3 text="第三者提供の制限" />
      <p>当社は、下記の場合を除き、事前に明らかにした利用目的の範囲を超えて個人情報を第三者に提供しません。<br />
        (1)あらかじめ本人の同意を得た場合<br />
        (2)法令により、または裁判所、警察などの公的機関から開示を求められた場合</p>
      <Heading.H3 text="法令の遵守" />
      <p>当社は、個人情報に関する法令等を遵守します。</p>
      <Heading.H3 text="個人情報保護の継続的な取り組み" />
      <p>当社は、今後とも社内一丸となって個人情報の保護に継続的に取組みます。</p>
      <Heading.H3 text="アクセス解析ツールについて" />
      <p>当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。このGoogleアナリティクスはトラフィックデータの収集のためにクッキー（Cookie）を使用しております。トラフィックデータは匿名で収集されており、個人を特定するものではありません。</p>
      <Heading.H3 text="制定日・改定日" />
      <p>制定：2014年3月1日</p>
    </div>
  </Layout>
)

export default Privacy;